import React, {
  Fragment,
  Dispatch,
  useEffect,
  SetStateAction,
  useState,
} from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';

import { styles as s, MuiButton } from '@onehope/design-system-ohw';
import {
  Icons,
  useTheme,
  useMediaQuery,
  GridV2 as Grid,
  ButtonBase,
} from '@onehope/design-system-v2';
import TabFilter from '../../TableComponents/TabFilter';
import SearchBar from '../../Events/EventsTable/SearchBar';
import Header from '../../Events/EventsTable/Header';
import TableListView from './TableBody';
import CardListView from '../../TableComponents/CardListView';
import TablePagination from '../../TableComponents/Pagination';
import NoEventsSVG from '../../../assets/noEvents';
import OrderAddDialog from '../../Events/AddOrderDialog';
import EventCopyUrlDialog from '../../Dialog/EventCopyUrlDialog';
import { clearLocalStorage } from '../../Dialog/EventCreateDialog/ClearLocalStorage';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';

import { EventStatusDictionary } from '../../../utils/enums';
import {
  CEDash_EventsV2Query_viewer_user_eventsV2_edges as EventsEdges,
  CEDash_EventsV2Query_viewer_user as UserType,
} from '../../../queries/generatedTypes/CEDash_EventsV2Query';
import { CEDash_EventsV2Query_viewer_user as ContactType } from '../../../queries/generatedTypes/CEDash_EventsV2Query';
import SortMenu from './SortMenu';
import { EventCreateSteps } from '../../Dialog/EventCreateDialog/stepperConstants';

const { cssConstants } = s;
const {
  AddCircleOutlinedIcon,
  AppsIcon: MuiAppsIcon,
  FormatListBulletedIcon: MuiFormatListBulletedIcon,
} = Icons;

const Container = styled.div`
  padding: 0 16px;
  font-family: ${cssConstants.bodyFontFamily};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

const AppsIcon = styled(MuiAppsIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const FormatListBulletedIcon = styled(MuiFormatListBulletedIcon)`
  && {
    margin-left: 8px;
    color: ${cssConstants.textColor};
  }
`;

const ToggleView = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding: 0 0 40px 0;
  }
`;

const GridContainer = styled(Grid)`
  padding: 16px 0 0 0;
  @media all and (min-width: 960px) {
    padding: 0;
  }
`;

const NoEventsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 16px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  @media all and (min-width: 768px) {
    padding: 40px 16px;
  }
`;

const NoEventsText = styled.div`
  color: ${cssConstants.textColor};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  padding: 24px 0;
`;

const NewEventButton = styled.div`
  max-width: 432px;
  width: 100%;
  height: 100px;
  color: ${cssConstants.primaryColor};
  cursor: pointer;
`;

const NewEventText = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 8px;
`;

const OrderTextContainer = styled.div`
  max-width: 432px;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface EventsTableProps {
  events: (EventsEdges | null)[] | null;
  contact?: ContactType;
  page: number;
  filterBy: string;
  setPageNumber: Dispatch<SetStateAction<string | string[]>>;
  setFilterBy: Dispatch<SetStateAction<string | null | undefined>>;
  handleSortEvents: (sortBy: string) => void;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  count: number;
  setSearchBy: Dispatch<SetStateAction<string | null | undefined>>;
  user: UserType;
  sortBy: string | null;
}

export default function EventsTable(props: EventsTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    page,
    setPageNumber,
    events,
    setFilterBy,
    filterBy,
    contact,
    handleSortEvents,
    setRowsPerPage,
    rowsPerPage,
    count,
    sortBy,
    setSearchBy,
  } = props;

  const { createEventStep } = queryString.parse(window.location.search);
  const { trackEventsV2 } = useTrackingContextValue();

  const [isSearchingBy, setIsSearchingBy] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const [isCopyUrlDialogOpen, setIsCopyUrlDialogOpen] = useState(false);
  const [isEventCreateOpen, setIsEventCreateOpen] = useState(!!createEventStep);
  const [selectedEventId, setSelectedEventId] = useState('');
  const [selectedTrinityPartyId, setSelectedTrinityPartyId] = useState('');

  const [isTableView, setIsTableView] = useState(
    localStorage.getItem('isTableView')
      ? localStorage.getItem('isTableView') === 'true'
      : true,
  );

  useEffect(() => {
    setIsEventCreateOpen(!!createEventStep);
  }, [createEventStep]);

  const toggleView = () => {
    localStorage.setItem('isTableView', `${!isTableView}`);
    setIsTableView(!isTableView);
  };

  const handleOrderAdd = (eventId: string | null | undefined) => {
    if (eventId) {
      setSelectedEventId(eventId);
      setIsOrderDialogOpen(!isOrderDialogOpen);
    }
  };

  const toggleEventCopyUrlDialog = (
    eventId: string | null | undefined,
    trinityPartyId: string | null | undefined,
  ) => {
    if (eventId || trinityPartyId) {
      eventId && setSelectedEventId(eventId);
      trinityPartyId && setSelectedTrinityPartyId(trinityPartyId);
      setIsCopyUrlDialogOpen(!isCopyUrlDialogOpen);
    }
  };

  const handleSearchEvents = (search: string) => {
    setSearchBy(search);
    setIsSearchingBy(true);
  };

  const handleFilterEvents = (filter: string) => {
    setIsFiltering(true);
    setFilterBy(filter);
  };

  const handleEventCreateOpen = () => {
    clearLocalStorage();
    trackEventsV2({ eventName: 'Event Create Started' });
    navigate(`/events/?createEventStep=${EventCreateSteps.DETAILS}`);
    return setIsEventCreateOpen(true);
  };

  const handleEventCreateClose = () => {
    navigate('/events');
    return setIsEventCreateOpen(false);
  };

  const contactName = contact?.fullName || contact?.organizationName;
  const text = contactName
    ? `${contactName} does not have any associated events.`
    : 'You do not have any events.';

  return (
    <Fragment>
      <OrderAddDialog
        selectCustomer
        isOpen={isOrderDialogOpen}
        selectedEventId={selectedEventId}
        handleClose={setIsOrderDialogOpen}
      />
      <EventCopyUrlDialog
        isOpen={isCopyUrlDialogOpen}
        selectedEventId={selectedEventId}
        selectedTrinityPartyId={selectedTrinityPartyId}
        setIsCopyUrlDialogOpen={setIsCopyUrlDialogOpen}
        setSelectedTrinityPartyId={setSelectedTrinityPartyId}
      />
      <Header
        user={props?.user}
        createEventStep={createEventStep}
        isEventCreateOpen={isEventCreateOpen}
        handleEventCreateOpen={handleEventCreateOpen}
        handleEventCreateClose={handleEventCreateClose}
      />
      {events || isSearchingBy || isFiltering ? (
        <Fragment>
          <Container>
            <Toolbar>
              <SearchBar
                handleSearch={handleSearchEvents}
                placeHolderText="Search events"
              />
              <GridContainer container alignItems="center" spacing={1}>
                <Grid item xs>
                  <TabFilter
                    filterBy={filterBy}
                    setFilterBy={handleFilterEvents}
                    tabValues={[
                      'all',
                      EventStatusDictionary.OPEN,
                      EventStatusDictionary.CLOSED,
                    ]}
                    tabLabels={['All', 'Open', 'Closed']}
                  />
                </Grid>
                {(isMobile || !isTableView) && (
                  <SortMenu
                    handleSortEvents={handleSortEvents}
                    sortBy={sortBy}
                  />
                )}
              </GridContainer>
              {!isMobile && (
                <ToggleView>
                  <ButtonBase onClick={toggleView}>
                    {isTableView ? <AppsIcon /> : <FormatListBulletedIcon />}
                  </ButtonBase>
                </ToggleView>
              )}
            </Toolbar>
            {!isMobile && isTableView ? (
              <TableListView
                events={events}
                handleOrderAdd={handleOrderAdd}
                handleSortEvents={handleSortEvents}
                setSelectedEventId={setSelectedEventId}
                contactId={contact?.accountId}
                toggleEventCopyUrlDialog={toggleEventCopyUrlDialog}
              />
            ) : (
              <CardListView
                listItems={events}
                baseRoute={'events'}
                cardSpecificProps={{
                  contactId: contact?.accountId,
                  setSelectedEventId,
                  handleOrderAdd,
                  toggleEventCopyUrlDialog,
                }}
              />
            )}
            <TablePagination
              page={page}
              count={count}
              rowsOnPage={(events && events.length) || 0}
              setPageNumber={setPageNumber}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              baseRoute={'events'}
            />
          </Container>
        </Fragment>
      ) : (
        <NoEventsContainer>
          <NoEventsSVG />
          <NoEventsText>{text}</NoEventsText>
          <NewEventButton>
            <MuiButton
              type="DASHED"
              fullWidth
              disableRipple
              onClick={handleEventCreateOpen}
            >
              <OrderTextContainer>
                <AddCircleOutlinedIcon />
                <NewEventText>add new event</NewEventText>
              </OrderTextContainer>
            </MuiButton>
          </NewEventButton>
        </NoEventsContainer>
      )}
    </Fragment>
  );
}
